import React, { Component ,  useState, useEffect } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import './scss/style.scss';

import { I18n } from '@aws-amplify/core';

import { strings } from './strings';

I18n.putVocabularies(strings);
I18n.setLanguage('es');


const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)



// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const Faq = React.lazy(() => import('./views/pages/faq/Faq'));
const Faq_en = React.lazy(() => import('./views/pages/faq/Faq-en'));
const Rules = React.lazy(() => import('./views/pages/rules/Rules'));
const Rules_en = React.lazy(() => import('./views/pages/rules/Rules-en'));


const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));

const Cert = React.lazy(() => import('./views/certification/Certification'));


function App() {
  //
  // const [teams, setTeams] = useState([]);
  // const [formData, setFormData] = useState(initialFormState);
  // useEffect(() => {
  //    fetchTeams();
  //  }, []);
  //
  //  async function fetchTeams() {
  //   const apiData = await API.graphql({ query: listTeams });
  //   setTeams(apiData.data.listTeams.items);
  // }
  //
  // async function createTeam() {
  //   if (!formData.name) return;
  //   await API.graphql({ query: createTeamMutation, variables: { input: formData } });
  //   setTeams([ ...teams, formData ]);
  //   setFormData(initialFormState);
  // }
  //
  // async function deleteTeam({ id }) {
  //   const newTeamsArray = teams.filter(team => team.id !== id);
  //   setTeams(newTeamsArray);
  //   await API.graphql({ query: deleteTeamMutation, variables: { input: { id } }});
  // }


    return (
      <HashRouter>

          <React.Suspense fallback={loading}>
          {/*

          #Conexion con DYNAMO DB
          <div class='dynamotable'>
          <h1> Teams</h1>
            <input
              onChange={e => setFormData({ ...formData, 'name': e.target.value})}
              placeholder="Team name"
              value={formData.name}
            />

            <button onClick={createTeam}>Create Team</button>
            <div style={{marginBottom: 30}}>
              {
                teams.map(team => (
                  <div key={team.id || team.name}>
                    <h2>{team.name}</h2>
                    <p>score: {team.score}</p>
                  </div>
                ))
              }
            </div>
          </div>*/}
            <Switch>

              <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} />
              <Route exact path="/register" name="Register Page" render={props => <Register {...props}/>} />
              <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
              <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
              <Route exact path="/faq" name="FAQ" render={props => <Faq {...props}/>} />
              <Route exact path="/en/faq" name="FAQ English" render={props => <Faq_en {...props}/>} />
              <Route exact path="/rules" name="Rules" render={props => <Rules {...props}/>} />
              <Route exact path="/en/rules" name="Rules English" render={props => <Rules_en {...props}/>} />
              <Route exact path="/cert" name="Certification Challenge" render={props => <Cert {...props}/>} />
              <Route path="/" name="Home" render={props => <TheLayout {...props}/>} />
            </Switch>
          </React.Suspense>
      </HashRouter>
    );

}

export default App
