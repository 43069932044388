export const strings = {
  es: {
    checkinSuccess: '¡Felicidades! Tu participación ha sido registrada',
    checkinAlready: 'Participación ya había sido registrada',
    invalidEvent:'Evento no válido'
  },
  en: {
    checkinSuccess: 'Congratulations! Your participation has been registered successfully',
    checkinAlready: 'Participation already registered',
    invalidEvent:'Invalid event'
  },
};